import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import DownloadTable12 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable12';
import DownloadTable13 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable13';
import DownloadTable20 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable20';
import DownloadTable21 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable21';
import DownloadTable22a from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable22a';
import DownloadTable22b from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable22b';
import DownloadTable23a from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable23a';
import DownloadTable23b from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable23b';
import DownloadTable24a from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable24a';
import DownloadTable24b from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable24b';
import DownloadTable25 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable25';
import DownloadTable26 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable26';
import DownloadTable27 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable27';
import DownloadTable30 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable30';
import NavButtons from 'components/Downloads/Desktop_Software/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision for Windows Desktop Downloads",
  "path": "/Downloads/Desktop_Software/InstarVision/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR Software and Documentation Downloads",
  "image": "../D_Desktop_Software_SearchThumb.png",
  "social": "/images/Search/D_Desktop_Software_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Downloads_white.webp",
  "chapter": "Downloads"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Download Area - Desktop Software' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Software Downloads and Usermanuals for your INSTAR Products' image='/images/Search/D_Downloads_SearchThumb.png' twitter='/images/Search/D_Downloads_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Downloads/Desktop_Software/InstarVision/' locationFR='/fr/Downloads/Desktop_Software/InstarVision/' crumbLabel="InstarVision" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#downloads",
        "aria-label": "downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Downloads`}</h1>
    <h2 {...{
      "id": "instarvision-for-windows-desktop-downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instarvision-for-windows-desktop-downloads",
        "aria-label": "instarvision for windows desktop downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InstarVision for Windows Desktop Downloads`}</h2>
    <h4 {...{
      "id": "desktop-software-for-your-network-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#desktop-software-for-your-network-cameras",
        "aria-label": "desktop software for your network cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Desktop software for your network cameras`}</h4>
    <p>{`InstarVision software helps you manage your cameras better. With this you can integrate up to 64 (!) Cameras depending on the license type and see them at the same time. The software also allows you to save images in different file formats and use advanced motion detection.`}</p>
    <a href="https://wiki.instar.com/Software/Windows/InstarVision"><EuiButton fill mdxType="EuiButton">Manual</EuiButton></a>
    <a href="https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_v3.0.msi"><EuiButton fill mdxType="EuiButton">Download</EuiButton></a>
    <EuiSpacer mdxType="EuiSpacer" />
    <h4 {...{
      "id": "version-history-with-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#version-history-with-features",
        "aria-label": "version history with features permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version history with features`}</h4>
    <h5 {...{
      "id": "version-30",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#version-30",
        "aria-label": "version 30 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version 3.0`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_v3.0.msi"
        }}>{`Download`}</a>{` `}{`[SHA265: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`7f809350e779a99c5afa5c35b202af2c33723fee99d7cabca2be23674cc1197d`}</code>{`]`}{` *`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/Software/Windows/InstarVision"
        }}>{`User Manual`}</a></li>
    </ul>
    <DownloadTable30 mdxType="DownloadTable30" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h5 {...{
      "id": "version-27",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#version-27",
        "aria-label": "version 27 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version 2.7`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_2.7.exe"
        }}>{`Download`}</a>{` `}{`[SHA265: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`26e6edb875a67dd69fcc94bd4b30154c39a562a08ec31e5d437e3f2d5374999f`}</code>{`]`}{` *`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/Software/Windows/InstarVision"
        }}>{`User Manual`}</a></li>
    </ul>
    <DownloadTable27 mdxType="DownloadTable27" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h5 {...{
      "id": "version-26",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#version-26",
        "aria-label": "version 26 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version 2.6`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_2.6.exe"
        }}>{`Download`}</a>{` `}{`[SHA265: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`563f8e3db342672d45c88b14262f419b4ee072c0b0b216436a304565cb522f07`}</code>{`]`}{` *`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/Software/Windows/InstarVision"
        }}>{`User Manual`}</a></li>
    </ul>
    <DownloadTable26 mdxType="DownloadTable26" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h5 {...{
      "id": "version-25r355",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#version-25r355",
        "aria-label": "version 25r355 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version 2.5r355`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_2.5.exe"
        }}>{`Download`}</a>{` `}{`[SHA265: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`091fafc2bedec78ad8922bc980d301fe1fbb1aa5f015d002a4253119783e2221`}</code>{`]`}{` *`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/Software/Windows/InstarVision"
        }}>{`User Manual`}</a></li>
    </ul>
    <DownloadTable25 mdxType="DownloadTable25" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h5 {...{
      "id": "version-24",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#version-24",
        "aria-label": "version 24 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version 2.4`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_2.4.exe"
        }}>{`Download`}</a>{` `}{`[SHA265: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`544c280cf361375d2d36d72f18dc22441da9d80f4e58638157506a4a1b34900f`}</code>{`]`}{` *`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/Software/Windows/InstarVision"
        }}>{`User Manual`}</a></li>
    </ul>
    <DownloadTable24a mdxType="DownloadTable24a" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DownloadTable24b mdxType="DownloadTable24b" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h5 {...{
      "id": "version-23",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#version-23",
        "aria-label": "version 23 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version 2.3`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_2.3.exe"
        }}>{`Download`}</a>{` `}{`[SHA265: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`8c66fe1dfa8bc808940383bffc8ce7a870c9717f5a686abbbd80cb6c98ad9471`}</code>{`]`}{` *`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/Software/Windows/InstarVision"
        }}>{`User Manual`}</a></li>
    </ul>
    <DownloadTable23a mdxType="DownloadTable23a" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DownloadTable23b mdxType="DownloadTable23b" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h5 {...{
      "id": "version-22",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#version-22",
        "aria-label": "version 22 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version 2.2`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_2.2.exe"
        }}>{`Download`}</a>{` `}{`[SHA265: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`621fc72609fc190fb6b27a69b786bdbee38d73a66b6afd0ace9d1c6e27f28cd7`}</code>{`]`}{` *`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/Software/Windows/InstarVision"
        }}>{`User Manual`}</a></li>
    </ul>
    <DownloadTable22a mdxType="DownloadTable22a" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DownloadTable22b mdxType="DownloadTable22b" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h5 {...{
      "id": "version-21-beta",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#version-21-beta",
        "aria-label": "version 21 beta permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version 2.1 BETA`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_BETA-2016-06-27.exe"
        }}>{`Download`}</a>{` `}{`[SHA265: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`3c7f7738cbcd0303c15a340f88895d5495689af8215c968343eb62431ca3fa33`}</code>{`]`}{` *`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/Software/Windows/InstarVision"
        }}>{`User Manual`}</a></li>
    </ul>
    <DownloadTable21 mdxType="DownloadTable21" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h5 {...{
      "id": "version-20",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#version-20",
        "aria-label": "version 20 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version 2.0`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_2.0.exe"
        }}>{`Download`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/Software/Windows/InstarVision"
        }}>{`User Manual`}</a></li>
    </ul>
    <DownloadTable20 mdxType="DownloadTable20" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h5 {...{
      "id": "version-13",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#version-13",
        "aria-label": "version 13 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version 1.3`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_1.3.exe"
        }}>{`Download`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/Instar_Vision_Man.pdf"
        }}>{`User Manual`}</a></li>
    </ul>
    <DownloadTable13 mdxType="DownloadTable13" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h5 {...{
      "id": "version-12",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#version-12",
        "aria-label": "version 12 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version 1.2`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_1.2.exe"
        }}>{`Download`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/Instar_Vision_Man.pdf"
        }}>{`User Manual`}</a></li>
    </ul>
    <DownloadTable12 mdxType="DownloadTable12" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h5 {...{
      "id": "obsolescent-versions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#obsolescent-versions",
        "aria-label": "obsolescent versions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Obsolescent Versions`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_1.1.exe"
        }}>{`Download v1.1`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionWindows/InstarVision_1.0.exe"
        }}>{`Download 1.0`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      